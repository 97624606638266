<template>
	<Main id="function">
		<div class="b-container p-1">
			<div class="mt-2">
				<h5>기능 테스트</h5>
			</div>
			<hr/>
			<b-card no-card-head class="mb-2">
				<b-form label-cols="3">
					<b-button-toolbar size="sm" class="mb-2">
						<div size="sm" class="fontsize-10 mr-3">IOT 기기등록: </div>
						<b-input-group size="sm" class="ml-2" prepend="기기 S/N">
							<b-form-input id="devSN" size="sm" type="text" v-model="data.devSN"></b-form-input>
						</b-input-group>
						<b-input-group size="sm" prepend="기기 이름"  class="ml-2">
							<b-form-input id="devName" size="sm" type="text" v-model="data.devName"></b-form-input>
						</b-input-group>
						<b-button size="sm" class="ml-2" @click="registerDevice()">기기등록요청</b-button>
					</b-button-toolbar>
					<b-input-group size="sm" class="mb-2" prepend="테스트 대상 기기 갯수" append="개">
						<b-form-input v-if="null != data.manager" id="testCount" size="sm" disabled v-model="data.manager.list.length"></b-form-input>
						<div v-else>현재 테스트 대상 기기 정보가 없습니다.</div>
					</b-input-group>
					<b-table 
						ref="devTable"
						v-if="data.manager!==null" small 
						:items="data.manager.list" :fields="fields"
						selectable
                        select-mode="multi"
                        @row-selected="checkSelect"
						:per-page="perPage"
						:current-page="page"
					>
						<template v-slot:head(selected)="">
                            <b-form-checkbox @change="selectAll" v-model="data.selectAll"></b-form-checkbox>
                        </template>
                        <template v-slot:cell(selected)="{ rowSelected }">
                            <!-- <i v-if="rowSelected" class="fas fa-check-square font-size-16 align-middle m-auto"></i>
                            <i v-else class="far fa-square font-size-16 align-middle"></i> -->
                            <img v-if="rowSelected" width="15px" src="@/assets/control/checkbox_select.png"/>
                            <img v-else width="15px" src="@/assets/control/checkbox_default.png"/>
                        </template>
						<template v-slot:cell(state)="row">
							<span v-if="parseInt(row.item.state, 10) !== 100" class="align-middle">{{ state_desc[parseInt(row.item.state, 10) + 1] }}</span>
							<span v-else class="align-middle">{{ error_desc }}</span>
						</template>
					</b-table>
					<div class="mt-3">
						<b-pagination v-if="data.manager!==null" size="sm" pills v-model="page" :total-rows="data.manager.list.length" :per-page="perPage" align="center"></b-pagination>
					</div>

					<b-row>
						<b-button-toolbar size="sm" class="m-auto">
							<b-button-group size="sm" class="mr-2">
								<b-btn ref="boot" type="button" size="sm" variant="primary" @click="startStop" :disabled="!data.manager || data.manager.list === null || data.manager.list.length === 0">{{ (data.manager && data.manager.started) ? '중지':'시작'}}</b-btn>
							</b-button-group>
							<b-button-group size="sm" class="mr-1">
								<b-btn ref="fire" size="sm" type="button" variant="danger" @click="fire" :disabled="!data.manager || data.manager.list === null || !data.manager.started">온도상승</b-btn>
								<b-btn ref="cooling" size="sm" type="button" variant="danger" @click="cooling" :disabled="!data.manager || data.manager.list === null || !data.manager.started">온도하강</b-btn>
								<b-btn ref="invasion" size="sm" type="button" variant="danger" @click="invasion" :disabled="!data.manager || data.manager.list === null || !data.manager.started">움직임</b-btn>
								<!-- <b-btn ref="warning" size="sm" type="button" variant="warning" @click="warning" :disabled="!data.manager || data.manager.list === null || !data.manager.started">경고</b-btn> -->
								<b-btn ref="deadSig" size="sm" type="button" variant="danger" @click="deadSig" :disabled="!data.manager || data.manager.list === null || !data.manager.started">움직임없음</b-btn>
								<b-btn ref="errorSig" size="sm" type="button" variant="danger" @click="errorSig" :disabled="!data.manager || data.manager.list === null || !data.manager.started">에러</b-btn>
							</b-button-group>
							<b-input-group size="sm" prepend="간격" append="sec" class="mr-1">
								<b-form-input id="duration" type="number" min="0" max="100" v-model="data.duration" style="min-width:100px;"></b-form-input>
							</b-input-group>
							<b-form-checkbox size="sm" v-model="data.useRate" @change="selectRate" class="m-auto ml-1 mr-0"></b-form-checkbox>
							<b-input-group size="sm" prepend="발생비율" append="%" class="mr-2">
								<b-form-input id="percent" type="number" min="0" max="100" v-model="data.rate" :disabled="!data.useRate" style="min-width:80px;"></b-form-input>
							</b-input-group>
							<b-button-group size="sm" class="mr-2">
								<b-btn ref="save" type="button" size="sm" variant="info" @click="save" class="float-right">현재상태 저장</b-btn>
							</b-button-group>
						</b-button-toolbar>
						<!-- <b-button-toolbar class="mt-2">
							<b-button-group size="sm" class="mr-2">
								스케줄:&nbsp;&nbsp;
								<b-btn ref="start" size="sm" type="button" variant="secondary" @click="schStart" :disabled="!data.manager || data.manager.list === null || !data.manager.started">시작</b-btn>
								<b-btn ref="stop" size="sm" type="button" variant="secondary" @click="schStop" :disabled="!data.manager || data.manager.list === null || !data.manager.started">중지</b-btn>
								<b-btn ref="reboot" size="sm" type="button" variant="secondary" @click="schReboot" :disabled="!data.manager || data.manager.list === null || !data.manager.started">리부트</b-btn>
							</b-button-group>
						</b-button-toolbar> -->
					</b-row>
				</b-form>
			</b-card>

			<b-card class="mb-2">
				<b-form-group label="데이타 로그" label-for="deviceLog">
					<b-form-textarea id="deviceLog" ref="deviceLog" v-model="data.deviceLog" rows="6" max-rows="8" disabled></b-form-textarea>
				</b-form-group>
				<b-btn ref="save" type="button" size="sm" variant="info" @click="clearLog" class="float-right">로그삭제</b-btn>
			</b-card>
		</div>
	</Main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Swal from '@/components/swal.js'
import { Device, DevManager } from '../device'
import Main from "./layout/main";

export default {
	name: 'function',
	components: { Main },
	data() {
		return {
			data: {
				jsonFile: null, 
				manager: null, 
				devSN: null, 
				devName: null, 
				duration: 10, 
				useRate: false,
				rate: 5, 
				deviceLog: '', 
				selectAll: false, 
                selectList: []
			}, 
			fields: [
                { key: 'selected', label: '선택' },
                { key: 'sn', label: '시리얼번호' },
                { key: 'name', label: '이름' },
                { key: 'buildingName', label: '빌딩' },
                { key: 'floorName', label: '층' },
                { key: 'state', label: '상태' },
            ], 
			state_desc: ['미등록', '오프라인', '센싱오프', '정상', '온도상승', '움직임', '경고', '움직임없음', '온도하강', '이벤트1', '이벤트2'], 
			error_desc: '오류',
			page: 1, 
			perPage: 15
		}
	},
	mounted() {
		console.log('static path:', __dirname)
        this.data.manager = new DevManager();
        this.data.manager.loadDevices();
        const list = this.data.manager.list;
        if (list === null || list.length <= 0) {
            Swal.warning('먼저 기기 초기화 데이타를 로드 하십시오.');
        } else {
			this.selectRate()
		}
	}, 
    computed: {
        ...mapState({
			devices: state => state.Devices.devices, 
			siteId: state => state.Server.siteId
        }),
        user () {
            return this.$session.getters.user;
        }
    }, 
	methods: {
		...mapActions({
            loadDevices: 'loadDevices', 
            saveDevices: 'saveDevices',
            clearDevices: 'clearDevices',
            addDevice: 'addDevice'
        }),
        open (link) {
            this.$electron.shell.openExternal(link)
        }, 
		selectAll(checked) {
			if (checked) {
                this.$refs.devTable.selectAllRows()
            } else {
                this.$refs.devTable.clearSelected()
            }
		}, 
        checkSelect(selects) {
			this.data.selectList = selects;
			if (null === this.data.manager) {
				this.data.selectAll = false
				return
			}
			let totalPage = parseInt(this.data.manager.list.length / this.perPage);
			const perLast = this.data.manager.list.length % this.perPage;
			totalPage += perLast > 0 ? 1:0;

            let maxSelect = this.perPage;
            if (this.page === totalPage) maxSelect = perLast;
			this.data.selectAll = (selects.length === maxSelect);
			// this.data.selectAll = (selects.length === this.data.manager.list.length)
			if (selects.length > 0) {
				console.log('select: ', selects);
				if (!this.data.devSN) {
					this.data.devSN = selects[0].sn;
				}
				if (!this.data.devName) {
					this.data.devName = selects[0].name;
				}
			}
		}, 
		selectRate() {
			if (this.data.manager === null) return;
			if (this.data.useRate) {
				const num = Math.round(this.data.manager.list.length * this.data.rate / 100)
				console.log('random devices: ', (num > 1 ? num : 1))
				this.data.manager.randomWorkDevices((num > 1 ? num : 1), true)
			}
			else {
				console.log('select devices: ', this.data.selectList.length)
				this.data.manager.setWorkDevices(this.data.selectList)
			}
		},
		addMessage(message) {
            this.data.deviceLog += message;
            this.data.deviceLog += '\n';
			// set end of scroll
			// set end of scroll
            setTimeout(() => {
				const deviceLog = document.getElementById('deviceLog');
				if (deviceLog) {
					deviceLog.scrollTop = deviceLog.scrollHeight;
					//deviceLog.scrollIntoView({behavior: "smooth", block: "end"})
				}
			}, 100)
        }, 
		registerDevice() {
			if (this.data.manager === null) return;
			if (this.data.manager.list.length > 0) {
				// let devData = this.data.manager.list[0].data;
				// devData.sn = this.data.devSN;
				// devData.name = this.data.devName;
				// const device = new Device(devData);
				const device = new Device(this.data.manager.list[0].data);
				device.bootForIOT(this.data.devSN, this.data.devName, this.siteId, this.mqttCallback);
				this.data.manager.insert(0, device)
				this.data.manager.saveDevices()
			}
		},
        startStop() {
			this.selectRate();
            if (this.data.manager.started) {
                // stop devices
                this.data.manager.shutdown();
            } else {
                // start devices
                this.data.manager.boot(this.mqttCallback);
			}
        },
        fire() {
            if (this.data.manager === null) return;
            this.selectRate();
            const count = Math.round(this.data.duration * 2 / 3);
            this.data.manager.fire(count, this.eventCallback);
        },
        cooling() {
            if (this.data.manager === null) return;
            this.selectRate();
            const count = Math.round(this.data.duration * 2 / 3);
            this.data.manager.cooling(count, this.eventCallback);
        },
        invasion() {
			if (this.data.manager === null) return;
			this.selectRate();
            const count = Math.round(this.data.duration * 2 / 3);
            this.data.manager.invasion(count, this.eventCallback);
        },
        warning() {
            if (this.data.manager === null) return;
            this.selectRate();
            const count = Math.round(this.data.duration * 2 / 3);
            this.data.manager.warning(count, this.eventCallback);
        },
        deadSig() {
            if (this.data.manager === null) return;
            this.selectRate();
            const count = Math.round(this.data.duration * 2 / 3);
            this.data.manager.notMove(count, this.eventCallback);
        },
        errorSig() {
            if (this.data.manager === null) return;
            this.selectRate();
            const count = Math.round(this.data.duration * 2 / 3);
            this.data.manager.sError(count, this.eventCallback);
        },
        schStart() {
            if (this.data.manager === null) return;
            this.selectRate();
            this.data.manager.schStart((event, device) => {});
        },
        schStop() {
            if (this.data.manager === null) return;
            this.selectRate();
            this.data.manager.schStop((event, device) => {});
        },
        schReboot() {
            if (this.data.manager === null) return;
            this.selectRate();
            this.data.manager.schReboot((event, device) => {});
        },
        save() {
            if (this.data.manager === null) return;
            this.data.manager.saveDevices();
            this.addMessage('현재 기기상태를 저장했습니다.');
        },
        clearLog() {
            this.data.deviceLog = '';
        },
        mqttCallback(topic, data) {
            if (topic.endsWith('initialize')) {
                const log = '[' + data.deviceName + '-' + data.sn + ']는 ' + (data.regStatus < 0 ? '미등록' : '등록') + '입니다.'
                this.addMessage(log);
            } else if (topic.endsWith('configuration')) {
				const log = '[' + data.deviceName + '-' + data.sn + ']에 ' + (data.command === 'cmd' ? '명령' : '설정') + '이 적용됩니다.'
				this.addMessage(log);
			}
        },
        eventCallback(event, device) {
            let message = null
            if (event === 'fire') { message = '[' + device.name + '-' + device.sn + ']센서에서 온도상승이 감지되었습니다.'; }
            if (event === 'cooling') { message = '[' + device.name + '-' + device.sn + ']센서에서 온도하강이 감지되었습니다.'; }
            if (event === 'invasion') { message = '[' + device.name + '-' + device.sn + ']센서에서 움직임이 감지되었습니다.'; }
            if (event === 'warning') { message = '[' + device.name + '-' + device.sn + ']센서에서 경고가 감지되었습니다.'; }
            if (event === 'notmove') { message = '[' + device.name + '-' + device.sn + ']센서에서 움직임없음 신호가 발생되었습니다.'; }
            if (event === 'error') { message = '[' + device.name + '-' + device.sn + ']센서에서 오류가 발생되었습니다.'; }
            if (event === 'active') { message = '[' + device.name + '-' + device.sn + ']센서가 정상 상태로 전환되었습니다.'; }
            if (event.endsWith('schedule start')) { message = '스케줄러에 의해 [' + device.name + '-' + device.sn + ']센서가 시작 되었습니다.'; }
            if (event.endsWith('schedule stop')) { message = '스케줄러에 의해 [' + device.name + '-' + device.sn + ']센서가 중지 되었습니다.'; }
            if (event.endsWith('schedule reboot')) { message = '스케줄러에 의해 [' + device.name + '-' + device.sn + ']센서가 리부트 되었습니다.'; }
            if (message) this.addMessage(message)
        }
	}
};
</script>
