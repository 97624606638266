import jwt from 'jsonwebtoken';

const state = {
    user: JSON.parse(localStorage.getItem('user'))
}

const mutations = {
    LOGIN_USER(state, {login, callback}) {
        const { id, pw } = login;
        try {
            if (process.env.VUE_APP_AUTH_ID === id && process.env.VUE_APP_AUTH_PW === pw) {
                const token = jwt.sign({
                    id: id, 
                    pw: pw
                }, process.env.VUE_APP_AUTH_SECRET);
                state.user = {
                    id: id, 
                    pw: pw, 
                    token: token
                }
                localStorage.setItem('user', JSON.stringify(state.user));
                console.log('success login');
                return callback(true);
            }
            console.log('fail login');
            return callback(false);
        } catch(err) {
            console.error('error login - ' + err);
            state.user = null;
            return callback(false);
        }
    }, 
    VERIFY_USER(state, callback) {
        if (!state.user) {
            return callback(false);
        }
        const { id, pw, token } = state.user;
        const decode = jwt.verify(token, process.env.VUE_APP_AUTH_SECRET);
        if (decode) {
            if (decode.id === id && decode.pw === pw) {
                return callback(true);
            }
        }
        return callback(false);
    },
    LOGOUT_USER(state) {
        state.user = null;
        localStorage.removeItem('user');
    }
}

const actions = {
    Login({ commit }, login) {
        return new Promise(resolve => {
            commit('LOGIN_USER', {
                login: login, 
                callback: resolve
            });
        });
    }, 
    Verify({ commit }) {
        return new Promise(resolve => {
            commit('VERIFY_USER', resolve);
        });
    }, 
    Logout({ commit }) {
        commit('LOGOUT_USER');
    }
}

export default {
	state,
	mutations, 
    actions
}