import Vue from 'vue'
import VueRouter from 'vue-router'
import Func from '../views/function.vue'
import Login from '@/views/login.vue';
import Scen from '../views/scenario.vue'
import Init from '../views/initialize.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'root',
		component: Func
	},
	{
		path: '/function',
		name: 'function',
		component: Func
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/initialize',
		name: 'initialize',
		component: Init
	},
	{
		path: '/volume',
		name: 'volume',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/volume.vue')
	},
	{
		path: '/scenario',
		name: 'scenario',
		component: Scen
	},
	{
		path: '/logout',
		name: 'logout'
	}
]

const router = new VueRouter({
	routes,
	// Simulate native-like scroll behavior when navigating to a new
	// route and using back/forward buttons.
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})


router.beforeResolve(async (routeTo, routeFrom, next) => {
	// const authPages = ['root', 'function', 'initialize', 'volume', 'scenario'];
	// const authRequired = authPages.includes(routeTo.name);
	const authRequired = routeTo.name != 'login';
	if (authRequired) {
		const result = await store.dispatch('Verify');
		if (!result) {
			return next('/login');
		}
	}
	if (routeTo.name === 'logout') {
		store.dispatch('Logout');
		return next({ name: 'login', replace: true });
	}
	next();
});

export default router
