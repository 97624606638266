<template>
	<Main class="initialize">
		<div class="mt-2">
			<h5>기기 관리</h5>
		</div>
		<hr/>
		<div class="col">
			<b-card no-card-head class="mb-2 p-1">
				<div>
					<b-input-group size="sm" prepend="적용 사이트" class="mb-2">
						<b-form-select id="site" v-model="option" @change="changeSite" :options="sites"></b-form-select>
					</b-input-group>
					<b-button-toolbar size="sm" class="mb-2">
						<div size="sm" class="fontsize-10 mr-3">DB에 기기정보 생성/삭제: </div>
						<b-input-group size="sm" class="ml-2" prepend="갯수" append="개">
							<b-form-input id="maxCount" size="sm" type="number" min="1" max="1000" v-model="data.count"></b-form-input>
						</b-input-group>
						<b-input-group size="sm" prepend="S/N접두어"  class="ml-2">
							<b-form-input id="maxCount" size="sm" type="text" v-model="data.preSN"></b-form-input>
						</b-input-group>
						<b-button size="sm" class="ml-2" @click="createDeviceInfos()">만들기</b-button>
						<b-button size="sm" class="ml-2" @click="deleteDeviceInfos()">삭제하기</b-button>
					</b-button-toolbar>
					<b-button-toolbar size="sm" class="mb-2">
						<b-input-group size="sm" class="mr-2">
							<div size="sm" class="fontsize-9 mr-3">DB에서 기기정보 가져오기: </div>
							<!-- <b-form-select id="site" v-model="option" :options="sites"></b-form-select> -->
							<b-button size="sm" class="ml-2" @click="requestDevices()">가져오기</b-button>
						</b-input-group>
						<b-input-group size="sm" prepend="테스트 대상 기기 갯수" append="개">
							<b-form-input v-if="null != data.manager" id="testCount" size="sm" disabled v-model="data.manager.list.length"></b-form-input>
							<div v-else>현재 테스트 대상 기기 정보가 없습니다.</div>
						</b-input-group>
					</b-button-toolbar>
				</div>
			</b-card>
			<b-card no-card-head class="mb-2 p-1">
				<div class="row mb-2">
					<b-button size="sm" variant="danger" @click="deleteDevice">기기삭제</b-button>
				</div>
				<b-table 
					ref="devTable"
					v-if="data.manager!==null" small 
					:items="data.manager.list" :fields="fields"
					selectable
					select-mode="multi"
					@row-selected="checkSelect"
					:per-page="perPage"
					:current-page="page"
				>
					<template v-slot:head(selected)="">
						<b-form-checkbox @change="selectAll" v-model="data.selectAll"></b-form-checkbox>
					</template>
					<template v-slot:cell(selected)="{ rowSelected }">
						<!-- <i v-if="rowSelected" class="fas fa-check-square font-size-16 align-middle m-auto"></i>
						<i v-else class="far fa-square font-size-16 align-middle"></i> -->
						<img v-if="rowSelected" width="15px" src="@/assets/control/checkbox_select.png"/>
						<img v-else width="15px" src="@/assets/control/checkbox_default.png"/>
					</template>
					<template v-slot:cell(state)="row">
						<span v-if="parseInt(row.item.state) !== 100" class="align-middle">{{ state_desc[parseInt(row.item.state) + 1] }}</span>
						<span v-else class="align-middle">{{ state_error }}</span>
					</template>
				</b-table>
				<div class="mt-3">
					<b-pagination v-if="data.manager!==null" size="sm" pills v-model="page" :total-rows="data.manager.list.length" :per-page="perPage" align="center"></b-pagination>
				</div>
			</b-card>
		</div>
	</Main>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Main from './layout/main';
import Swal from '@/components/swal.js'
import { Device, DevManager } from '../device';
import config from '@/config';

export default {
	name: 'initialize',
	components: { Main }, 
	data() {
		return {
			option: {
				siteId: null, 
				userId: null
			}, 
			data: {
				manager: null, 
				count: 100, 
				preSN: 'TEST_', 
				selectAll: false, 
                selectList: []
			}, 
			jsonFile: null,
			sites: [],
			fields: [
				{ key: 'selected', label: '선택' },
                { key: 'sn', label: '시리얼번호' },
                { key: 'name', label: '이름' },
                { key: 'buildingName', label: '빌딩' },
				{ key: 'floorName', label: '층' },
                { key: 'state', label: '현재 상태' }
			],
			state_desc:['미등록', '오프라인', '센싱오프', '정상', '화재', '침입', '경고', '움직임없음', '공조기가동', '이벤트1', '이벤트2'], 
			state_error: '오류', 
			backendApi: process.env.VUE_APP_BACKEND_API, 
			page: 1, 
			perPage: 15, 
			perLast: 15
		}
	}, 
	computed: {
		...mapState({
			siteId: state => state.Server.siteId, 
			serverType: state => state.Server.serverType
		})
	}, 
	mounted(){
		this.$http.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoxLCJpZCI6ImhoaGFAZGlyZWN0aW9uc29mdC5jb20iLCJzaXRlX2lkIjo0LCJpYXQiOjE2MDI1NjM5MTJ9.CDWkwm5aDJY1GLprvuXmjO4jlzmAnHa2p2mHfOr4nZs';
		// this.backendApi = this.serverType === 'dev' ? 'https://sofisems.com:8080/api':'https://sofisems.com/api';
		this.backendApi = config[this.serverType].backendApi;
		console.log(`API: ${this.backendApi}`)
		this.option.siteId = this.siteId;
		this.listSites()
		this.data.manager = new DevManager()
		this.data.manager.loadDevices()
	}, 
	methods: {
		...mapActions(['setSiteId']), 
		selectAll(checked) {
			if (checked) {
                this.$refs.devTable.selectAllRows()
            } else {
                this.$refs.devTable.clearSelected()
            }
		}, 
        checkSelect(selects) {
			this.data.selectList = selects;
			if (null === this.data.manager) {
				this.data.selectAll = false
				return
			}
			let totalPage = parseInt(this.data.manager.list.length / this.perPage);
			const perLast = this.data.manager.list.length % this.perPage;
			totalPage += perLast > 0 ? 1:0;

            let maxSelect = this.perPage;
            if (this.page === totalPage) maxSelect = perLast;
			this.data.selectAll = (selects.length === maxSelect);
			// this.data.selectAll = (selects.length === this.data.manager.list.length)
			if (selects.length > 0) {
				console.log('select: ', selects);
				if (!this.data.devSN) {
					this.data.devSN = selects[0].sn;
				}
				if (!this.data.devName) {
					this.data.devName = selects[0].name;
				}
			}
		},
		listSites() {
            this.$http.get(`${this.backendApi}/user`, {
                params: {
                    skip: 0,
                    type: 0, 
                    //limit: this.limit
                }
            })
                .then((res) => {
                    console.log('data:', res.data);
                    this.sites = []; // 회사는 전체가 필요 없음
					let option = null
                    if (res.data.rows) {
                        res.data.rows.forEach((site, idx) => {
                            if (site.type !== 0) { // 개인회원은 제외
                                this.sites.push({
                                    text: site.company_name + '-' + site.users[0].user_name + '(' + site.users[0].user_id + ')', 
                                    value: { siteId: site.id, userId: site.users[0].user_id }
								});
								if (this.option.siteId === site.id) {
									option = { siteId: site.id, userId: site.users[0].user_id }
								}
							}
							if (idx >= res.data.rows.length - 1) {
								if (!option) {
									if (this.sites.length > 0) {
										if (!this.option.siteId || this.option.siteId === null) {
											this.option = this.sites[0].value
											this.setSiteId(this.option.siteId)
										}
									}
								} else {
									this.option = option
									this.setSiteId(this.option.siteId)
								}
							}
						});
					}
                })
                .catch((err) => {
                    if (err.message) { 
						return Swal.warning(err.message)
					}
					Swal.warning('사이트 목록을 가져올수 없습니다.')
                });
		},
		changeSite(e) {
			console.log('current site: ', this.option.siteId)
			this.setSiteId(this.option.siteId);
		},
		requestDevices() {
			Swal.confirm('기기정보 가져오기', 'DB에서 기기정보를 가져오면 테스트중인 기기정보는 사라집니다. DB에서 기기정보를 가져오시겠습니까?')
				.then(result => {
					if (!result) { 
						throw new Error('기기정보 가져오기를 취소했습니다.') 
					}
					this.listDevices()
				});
		},
		listDevices() {
			this.$http.get(`${this.backendApi}/device`, {
				params: {
					site_id: this.option.siteId, 
					user_type: 0 // 관리자용 목록을 요청
				}
			})
				.then(res => {
					if (!res.data.result) {
						Swal.warning(res.data.message)
						throw new Error(res.data.message)
					}
					if (res.data.rows) {
						this.data.manager.removeAll();
						res.data.rows.forEach((device, idx) => {
							console.log('device:', device);
							const dev = {
								sn: device.sn, 
								name: device.name,
								state: parseInt(device.state), 
								siteId: this.option.siteId,
								buildingId: device.floor ? device.floor.build_id : null,
								floorId: device.floor_id,
								buildingName: (device.floor && device.floor.building) ? device.floor.building.building_name : null,
								floorName: device.floor ? device.floor.floor_name : null,
								base: device.floor ? device.floor.base : null,
								logState: device.log_state,
								typeSensor: device.type_sensor ? device.type_sensor : 0,
								typeHW: device.type_hw,
								typeSW: device.type_sw,
								verFW: device.firmware_ver,
								detectType: device.detect_type,
								detectTypeConv: device.detect_type_conv,
								sensitivityFire: device.sensitivity_fire,
								sensitivityIntr: device.sensitivity_intr,
								checkInterval: device.check_interval,
								checkRepeat: device.check_repeat,
								checkRepeatInterval: device.check_repeat_interval,
								checkRepeatCount: device.check_repeat_count,
								videoUrl: device.video_url,
								sche: [],
								// sound: [],
								// thr: {
								// 	ccLow: device.th_cc_low, 
								// 	ccHigh: device.th_cc_high, 
								// 	cc2: device.th_cc_2, 
								// 	deltaT: device.th_delta_t, 
								// 	channelNum: device.th_channel_num, 
								// 	value1: device.th_value_1, 
								// 	value2: device.th_value_2, 
								// 	value3: device.th_value_3, 
								// },
								// als: {
								// 	num1: device.alsa_num_1, 
								// 	num2: device.alsa_num_2, 
								// 	num3: device.alsa_num_3, 
								// 	num4: device.alsa_num_4, 
								// 	num5: device.alsa_num_5, 
								// }
								def: null, 
								thr: null, 
								alsa: null, 
								sound: null
							}
							if (device.devExt) {
								// 기기 기본값 변환
								var def = {};
								for (var i = 1; i <= 15; i++) {
									const source = 'def_num_' + i;
									const target = 'num' + i;
									if (typeof device.devExt[source] !== 'undefined')
										def[target] = device.devExt[source]
								}
								if (!this.isEmptyObj(def)) {
									dev.def = def;
								}
								// Threshold 값 변환
								var thr = {};
								for (i = 1; i <= 15; i++) {
									const source = 'thr_num_' + i;
									const target = 'num' + i;
									if (typeof device.devExt[source] !== 'undefined')
										thr[target] = device.devExt[source]
								}
								if (!this.isEmptyObj(thr)) {
									dev.thr = thr;
								}
								// Alsamixer 값 변환
								var alsa = {};
								for (i = 1; i <= 15; i++) {
									const source = 'alsa_num_' + i;
									const target = 'num' + i;
									if (typeof device.devExt[source] !== 'undefined')
										alsa[target] = device.devExt[source]
								}
								if (!this.isEmptyObj(alsa)) {
									dev.alsa = alsa;
								}
								// 사운드값 변환
								var sound = {};
								for (i = 1; i <= 15; i++) {
									const source = 'sound_num_' + i;
									const target = 'num' + i;
									if (typeof device.devExt[source] !== 'undefined')
										sound[target] = device.devExt[source]
								}
								if (!this.isEmptyObj(sound)) {
									dev.sound = sound;
								}
							}
							this.data.manager.add(new Device(dev));
							if (idx >= res.data.rows.length - 1) {
								this.data.manager.saveDevices()
								Swal.success('선택한 사이트로 부터 기기목록을 얻어왔습니다.')
							}
						});
					}
				})
				.catch((err) => {
					console.log('error: ', err)
                });
		},
		isEmptyObj(param) {
			return Object.keys(param).length === 0 && param.constructor === Object;
		},
        padStart(num, length) {
            var r = '' + num;
            while (r.length < length) {
                r = '0' + r;
            }
            return r;
        }, 
		createDeviceInfos() {
			const startSN = this.data.preSN + this.padStart(1, 4)
			const endSN = this.data.preSN + this.padStart(this.data.count, 4)
			const message = `선택된 사이트에 테스트용 기기 S/N ${startSN} 부터 ${endSN} 까지 ${this.data.count}개의 정보를 만듭니다. 새로운 기기정보를 만들겠습나까?`
			Swal.confirm('기기정보 만들기', message)
				.then(result => {
					if (!result) {
						return;
					}
					let promise = []
					for (var i = 1; i < this.data.count; i++) {
						const sn = this.data.preSN + this.padStart(i, 4);
						const name = '테스트 ' + this.padStart(i, 4);

						// insert device info into database
						const request = this.$http.post(`${this.backendApi}/device`, {
							site_id: this.option.siteId, 
							sn: sn,
							name: name
						});
						promise.push(request);

						// add device info
						// const device = this.data.manager.makeDevice(sn, name);
						// this.data.manager.add(new Device(device));
					}
					Promise.all(promise)
						.then(results => {
							let errorList = []
							results.forEach((res, idx) => {
								if (!res.data.result) errorList.push(res.data.message)
								if (idx >= results.length - 1) {
									if (errorList.length > 0) {
										if (errorList.length < results.length) {
											Swal.warning(errorList.length + ' 개의 기기정보가 생성에 실패 했습니다.')
										} else {
											Swal.warning('새로운 기기정보 생성에 실패 했습니다.')
										}
										return;
									}
									Swal.success('새로운 기기정보를 만들었습니다.');
									this.listDevices();
								}
							})
						})
						.catch(error => {
							console.error(error);
						})
				})
				.catch(error => {
					console.log('error: ', error)
				});
		}, 
		deleteDeviceInfos() {
			const startSN = this.data.preSN + this.padStart(1, 4)
			const endSN = this.data.preSN + this.padStart(this.data.count, 4)
			const message = `선택된 사이트에 기기 S/N ${startSN} 부터 ${endSN} 까지 ${this.data.count}개의 정보를 삭제합니다. 기기정보를 삭제 하시겠습나까?`
			let snList = []
			let ids = []
			for (var i = 1; i < this.data.count; i++) {
				const sn = this.data.preSN + this.padStart(i, 4);
				snList.push(sn);
			}
			Swal.confirm('기기정보 삭제하기', message)
				.then(result => {
					if (!result) {
						return;
					}
					return this.$http.get(`${this.backendApi}/device`, {
                        params: { site_id: this.option.siteId}
                    });
				})
				.then(res => {
					if (!res.data.result) throw new Error(res.data.message);
					const rows = res.data.rows;
					const request = rows.forEach((row) => {
						const idx = snList.findIndex(sn => sn === row.sn)
						if (idx >= 0) ids.push(row.id);
						// if (idx >= 0) {
						// 	const request = this.$http.delete(`${this.backendApi}/device/${row.id}`)
						// 	promise.push(request)
						// }
                    });
                    const promise = [request];
                    return Promise.all(promise);
				})
				.then(results => {
					return this.$http.delete(`${this.backendApi}/device/`, { params: { ids: ids }})
				})
				.then(res => {
					if (!res.data.result) {
						Swal.error(res.data.message);
						return;
					}
					Swal.success('지정된 기기정보를 삭제했습니다.');
					this.listDevices();
				})
				.catch(error => {
					console.log('error: ', error)
				});
		}, 
		deleteDevice() {
			if (this.data.selectList.length <= 0) {
				Swal.warning('먼저 삭제할 기기를 선택하십시오.');
				return;
			}
			Swal.confirm('기기 삭제', '기기를 삭제할 경우 기기와 관련된 모든정보가 삭제됩니다.\n그래도 선택한 기기를 삭제하시겠습니까?')
				.then(result => {
					if (!result) {
						return;
					}
					return Promise.all(
						this.data.selectList.map(device => {
							return this.$http.delete(`${this.backendApi}/device/sn`, {
								params: { sn: device.sn, user_id: this.option.userId }
							});
						})
					);
				})
				.then(resList => {
					resList.forEach((res, idx) => {
						if(!res.data.result) {
							console.log('error(', res.data.code, '): ', res.data.message);
							throw new Error('기기삭제에 실패 했습니다.');
						}
						if (idx >= resList.length - 1) {
							Swal.success('기기삭제에 성공했습니다.');
							this.listDevices();
						}
					})
				})
				.catch(error => {
					Swal.error(error.message);
				})
		}
	}
}
</script>
