<template>
	<div>
        <div class="col-12 m-auto">
            <slot />
        </div>
	</div>
</template>
<script>

export default {
    name: 'auth'
};
</script>

<style scoped>
</style>