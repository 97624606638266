module.exports = {
    dev1: {
        region: 'us-east-2', // direction-hhha
        host: 'az8uxat7vu9r7-ats.iot.us-east-2.amazonaws.com',
        poolId: 'us-east-2:c60db73f-f8e5-4fb6-a46a-69dc85d7d193', 
        backendApi: 'https://sofisems.com:8080/api'
    }, 
    dev: {
        region: 'ap-northeast-2', // directionsoft2020
        host: 'a2ql6tfljaqf4k-ats.iot.ap-northeast-2.amazonaws.com',
        poolId: 'ap-northeast-2:1489f69d-5c93-407d-bceb-d6a49d15e242', 
        backendApi: 'https://sofisems.com:8080/api'
    }, 
    dev3: {
        region: 'ap-northeast-2', // development secuworks
        host: "a215ufsmrbro4r-ats.iot.ap-northeast-2.amazonaws.com",
        poolId: 'ap-northeast-2:7fa8bd8c-70d0-48c6-965e-5ed26cead3e3', 
        backendApi: 'https://sofisems.com:8080/api'
    },
    pro: {
        region: 'ap-northeast-2', // service secuworks
        host: "a215ufsmrbro4r-ats.iot.ap-northeast-2.amazonaws.com",
        poolId: 'ap-northeast-2:7fa8bd8c-70d0-48c6-965e-5ed26cead3e3', 
        backendApi: 'https://sofisems.com/api'
    }, 
    cld: {
        region: null,
        host: "ws://182.208.112.254:8080/mqtt", 
        proxyHost: "http://182.208.112.254:8080",
        poolId: null, 
        backendApi: 'http://182.208.112.254:3012/api'
    }, 
    sws: { // secuworks closed(임시)
        region: null,
        host: "ws://secuworks.asuscomm.com:3009/mqtt", 
        proxyHost: "http://secuworks.asuscomm.com:3009",
        poolId: null, 
        backendApi: 'http://secuworks.asuscomm.com:3012/api'
    }
}
