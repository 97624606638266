import sweetalert from 'sweetalert'

export default {
    success(msg) {
        return sweetalert({
            icon: 'success',
            title: '성공',
            text: msg
        });
    },
    warning(msg) {
        return sweetalert({
            icon: 'warning',
            title: '경고',
            text: msg
        });
    },
    error(msg) {
        return sweetalert({
            icon: 'error',
            title: '오류',
            text: msg
        });
    }, 
    confirm(title, message) {
        return sweetalert({
            icon: 'info',
            title: title,
            text: message,
            dangerMode: true, 
            buttons: {
                cancel: {
                    text: '취소', 
                    visible: true
                }, 
                confirm: {
                    text: '확인'
                }
            }
        });
    }
}