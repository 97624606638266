<template>
	<div class="main">
        <Menu class="leftmenu"></Menu>
        <div class="col-12">
            <slot />
        </div>
	</div>
</template>
<script>
//import Menu from "@/components/menu";
import Menu from "../../components/menu";

export default {
    name: 'frame', 
    components: { Menu }
};
</script>
<style scoped>

.main {
    margin-left: 200px; /* Same as the width of the sidebar */
    padding: 0px 10px;
}

.leftmenu {
	height: 100%;
	width: 200px;
	--z-index: 1;
	position: fixed;
	top: 0;
	left: 0;
	overflow-x: hidden; /* Disable horizontal scroll */
	padding-top: 20px;
}

</style>