const state = {
    devices: []
}
  
const mutations = {
    LOAD_DEVICES () {
        const data = localStorage.getItem('testDevices')
        if (data) {
            state.devices = JSON.parse(data)            
        }
    },
    SAVE_DEVICES () {
        localStorage.setItem('testDevices', JSON.stringify(state.devices))
    },
    CLEAR_DEVICES () {
        state.devices = []
    },
    ADD_DEVICE (device) {
        // state.devices.push(device)
        state.devices = [...state.devices, device]
    },
    UPDATE_DEVICE (device) {
        const index = state.devices.findIndex(dev => dev.deviceId === device.deviceId)
        if (index >= 0) {
            state.devices[index] = device
        }
    },
    REMOVE_DEVICE (deviceId) {
        const index = state.devices.findIndex(dev => dev.deviceId === deviceId)
        if (index >= 0) {
            state.devices.splice(index, 1)
        }
    }
}
  
const actions = {
    loadDevices ({ commit } ) {
        // do something async
        commit('LOAD_DEVICES')
    },
    saveDevices ({ commit } ) {
        // do something async
        commit('SAVE_DEVICES')
    },
    clearDevices ({ commit } ) {
        // do something async
        commit('CLEAR_DEVICES')
    },
    addDevice ({ commit }, device ) {
        // do something async
        commit('ADD_DEVICE', device)
    },
    updateDevice ({ commit }, device ) {
        // do something async
        commit('UPDATE_DEVICE', device)
    },
    deleteDevice ({ commit }, deviceId ) {
        // do something async
        commit('DELETE_DEVICE', deviceId)
    },
}
  
export default {
    state,
    mutations,
    actions
}
  