<template>
    <auth class="cols-4">
        <b-form>
            <b-row align-v="center" style="min-height: 600px;">
                <b-col class="mx-auto" style="max-width:500px;">
                    <b-card size="sm" align="center" class="cols-12">
                        <b-card-header>LOGIN</b-card-header>
                        <b-card-body class="py-1">
                            <b-row class="my-1">
                                <b-col sm="3">
                                    <label class="pt-2">ID</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input 
                                        v-model="id" required
                                        placeholder="디렉션소프트 Admin용 ID를 입력하세요"
                                    />
                                </b-col>
                            </b-row>
                            <b-row class="my-1 mt-2">
                                <b-col sm="3">
                                    <label class="pt-2">PW</label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input 
                                        v-model="pw" type="password" required
                                        placeholder="디렉션소프트 Admin용 PW를 입력하세요"
                                    />
                                </b-col>
                            </b-row>
                            <b-row sm="12" class="my-2" style="background:#dddddd; height:1px;"/>
                            <b-row class="mt-3">
                                <b-col><b-btn variant="primary" @click="onLogin">로그인</b-btn></b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-form>
    </auth>
</template>


<script>
import { mapActions } from 'vuex';
import Auth from './layout/auth';
import Swal from '@/components/swal.js'

export default {
    name: 'login', 
    components: {
        Auth
    }, 
    data() {
        return {
            id: null,
            pw: null
        }
    }, 
    methods: {
        ...mapActions({
            login: 'Login', 
        }),
        async onLogin() {
            try {
                const res = await this.login({
                    id: this.id, 
                    pw: this.pw
                });
                console.log('res: ', res);
                if (res) {
                    this.$router.push('/#/function');
                }
                else {
                    this.id = '';
                    this.pw = '';
                    Swal.warning('로그인에 실패 했습니다.');
                }
            } catch(err) {
                console.error('onLogin' + err);
            }
        }
    }
}
</script>
