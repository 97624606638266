<template>
	<Main class="simulation">
		<div class="mt-2">
			<h5>시나리오 테스트</h5>
		</div>
		<hr/>
		<div class="col">
			<b-card no-header class="mb-2">
				<b-input-group size="sm" prepend="시나리오" class="mb-2">
					<b-form-file 
						id="scenario" 
						size="sm"
						v-model="data.jsonScenario"
						placeholder="시나리오파일 선택"
						@input.end="loadScenario"
						:disabled="!data.manager || data.manager.list.length <= 0"/>
				</b-input-group>

				<b-input-group size="sm" class="mb-2" prepend="테스트 대상 기기 갯수" append="개">
					<b-form-input v-if="null != data.manager" id="testCount" size="sm" disabled v-model="data.manager.list.length"></b-form-input>
					<div v-else>현재 테스트 대상 기기 정보가 없습니다.</div>
				</b-input-group>

				<b-table 
					ref="devTable"
					v-if="data.manager!==null" small 
					:items="data.manager.list" :fields="fields"
					selectable
					select-mode="multi"
					@row-selected="checkSelect"
					:per-page="perPage"
					:current-page="page"
				>
					<template v-slot:head(selected)="">
						<b-form-checkbox @change="selectAll" v-model="data.selectAll"></b-form-checkbox>
					</template>
					<template v-slot:cell(selected)="{ rowSelected }">
						<!-- <i v-if="rowSelected" class="fas fa-check-square font-size-16 align-middle m-auto"></i>
						<i v-else class="far fa-square font-size-16 align-middle"></i> -->
						<img v-if="rowSelected" width="15px" src="@/assets/control/checkbox_select.png"/>
						<img v-else width="15px" src="@/assets/control/checkbox_default.png"/>
					</template>
					<template v-slot:cell(state)="row">
						<span class="align-middle">{{ state_desc[parseInt(row.item.state, 10) + 1] }}</span>
					</template>
				</b-table>
				<div class="mt-3">
					<b-pagination v-if="data.manager!==null" size="sm" pills v-model="page" :total-rows="data.manager.list.length" :per-page="perPage" align="center"></b-pagination>
				</div>

				<b-button 
					size="sm" variant="primary" @click="startScenarioTest()"
					:disabled="!data.manager || data.manager.list.length <= 0 || !data.scenario"
				>시나리오 시작</b-button>
				<b-button 
					size="sm" class="ml-2" variant="primary" @click="stopScenarioTest()"
					:disabled="!data.manager || data.manager.list.length <= 0 || !data.scenario"
				>시나리오 종료</b-button>
			</b-card>
		</div>
		<div class="col">
			<b-card class="mb-2">
				<b-form-group label="데이타 로그" label-for="deviceLog">
					<b-form-textarea id="deviceLog" ref="deviceLog" v-model="data.deviceLog" rows="6" max-rows="8" disabled></b-form-textarea>
				</b-form-group>
				<b-btn ref="save" type="button" size="sm" variant="info" @click="clearLog" class="float-right">로그삭제</b-btn>
			</b-card>
		</div>
	</Main>
</template>
<script>
import Main from './layout/main';
import Swal from '@/components/swal.js'
import { Device, DevManager } from '../device';

export default {
	name: 'scenario',
	components: { Main }, 
	mounted(){
		this.data.manager = new DevManager();
        this.data.manager.loadDevices();
        const list = this.data.manager.list;
        if (list === null || list.length <= 0) {
            Swal.warning('먼저 기기 초기화 데이타를 로드 하십시오.');
        }
	}, 
	data() {
		return {
			data: {
				jsonScenario: null, 
				scenario: null, 
				manager: null, 
				selectAll: false, 
				selectList: [], 
				deviceLog: null
			}, 
			fields: [
                { key: 'selected', label: '선택' },
                { key: 'sn', label: '시리얼번호' },
                { key: 'buildingName', label: '빌딩' },
                { key: 'floorName', label: '층' },
                { key: 'state', label: '상태' },
            ], 
			state_desc: ['미등록', '오프라인', '센싱오프', '정상', '화재', '침입', '경고', '오류'], 
			page: 1, 
			perPage: 15
		}
	}, 
	methods: {
		selectAll(checked) {
			if (checked) {
                this.$refs.devTable.selectAllRows()
            } else {
                this.$refs.devTable.clearSelected()
            }
		}, 
        checkSelect(selects) {
			this.data.selectList = selects;
			if (null === this.data.manager) {
				this.data.selectAll = false
				return
			}
			let totalPage = parseInt(this.data.manager.list.length / this.perPage);
			const perLast = this.data.manager.list.length % this.perPage;
			totalPage += perLast > 0 ? 1:0;

            let maxSelect = this.perPage;
            if (this.page === totalPage) maxSelect = perLast;
			this.data.selectAll = (selects.length === maxSelect);
			// this.data.selectAll = (selects.length === this.data.manager.list.length)
		},
		loadScenario() {
			console.log('json file path:', this.data.jsonScenario.path);
            const fs = require('fs');
            const data = fs.readFileSync(this.data.jsonScenario.path, { charset: 'utf8' })
            this.data.scenario = JSON.parse(data)
            // this.addMessage('시나리오를 로드 합니다.')
            Swal.success('시나리오를 로드 합니다.')
		}, 
        startScenarioTest() {
			// this.addMessage('시나리오 테스트를 시작 합니다.')
            Swal.success('시나리오 테스트를 시작 합니다.')
		}, 
		stopScenarioTest() {
			// this.addMessage('시나리오 테스트를 종료 합니다.')
            Swal.success('시나리오 테스트를 종료 합니다.')
		},
        clearLog() {
            this.data.deviceLog = '';
        }, 
		addMessage(message) {
            this.data.deviceLog += message;
            this.data.deviceLog += '\n';
            // set end of scroll
            // set end of scroll
            setTimeout(() => {
				const deviceLog = document.getElementById('deviceLog');
				if (deviceLog) {
					deviceLog.scrollTop = deviceLog.scrollHeight;
					//deviceLog.scrollIntoView({behavior: "smooth", block: "end"})
				}
			}, 100)
        }
	}
}
</script>
