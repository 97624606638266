import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import sweetalert from 'sweetalert'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false
Vue.http = Vue.prototype.$http = axios
Vue.prototype.$sweetalert = sweetalert

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

console.log('currnt path1: [', __dirname, ']');
console.log('currnt file1: [', __filename, ']');
console.log('currnt path1: [', process.cwd(), ']');

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with Request error
  return Promise.reject(error);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
