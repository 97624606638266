const AWS = require('aws-sdk')
const AWSIOTData = require('aws-iot-device-sdk')
const HttpsProxyAgent = require('https-proxy-agent')
const url = require('url')
// import store from '@/state/store'
const store = require('@/store')
const axios = require('axios')
const mqtt = require('mqtt')

// 기기 상태
const Events = {
    Unregist: -1, 
    Offline: 0, 
    Standby: 1,
    Active: 2, 
    Fire: 3, 
    Invasion: 4, 
    Warning: 5, 
    NotMove: 6, 
    Cooling: 7, 
    Event1: 8, 
    Event2: 9, 
    Error: 100, 
    isEvent: (state) => {
        // Fire, Invasion, Warning, NotMove, Cooling, Event1, Event2
        return (state >= Events.Fire || state <= Events.Event2);
    }, 
    isOnline: (state) => {
        // Standby, Active, Fire, Invasion, Warning, NotMove, Cooling, Event1, Event2
        return (state >= Events.Standby && state != Events.Error);
    }, 
    isOffline: (state) => {
        // Unregist, Offline
        return (state < Events.Standby && state != Events.Error);
    }
}

// 기기이벤트 메시지
const fireEvt = 'fire'
const coolingEvt = 'cooling'
const invasionEvt = 'invasion'
const warningEvt = 'warning'
const notMoveEvt = 'notmove'
const errorEvt = 'error'
const activeEvt = 'active'
const schStartEvt = 'schedule start'
const schStopEvt = 'schedule stop'
const schRebootEvt = 'schedule reboot'

const configF = require('../config')
// console.log('config:', configF);

const serverTypeF = store.default.state['Server']['serverType']
const serverType = serverTypeF ? serverTypeF:'dev'
// const mqttType = serverType === 'pro' ? 'sofis_svc': 'sofis_dev'
const mqttType = serverType === 'pro' ? '': 'sofis_dev'
console.log('serverTYpe: ', serverType)
const config = configF[serverType]
console.log('config:', config)

let caData = null
let certData = null
let keyData = null

if (config.region) {
    AWS.config.region = config.region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.poolId
    })
} else {
    console.log('cert: closed!')
    axios.get('/certification/ca.crt')
        .then(data => {
            caData = data
            console.log('CA: ', caData)
        })
        .catch(error => console.log(error))
    axios.get('/certification/client.crt')
        .then(data => {
            certData = data
            console.log('cert: ', certData)
        })
        .catch(error => console.log(error))
    axios.get('/certification/client.key')
        .then(data => {
            keyData = data
            console.log('key: ', keyData)
        })
        .catch(error => console.log(error))
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
export class Device {
    constructor(device = {}) {
        console.log('create device: ', device)
        this.sn = device.sn ? device.sn : null
        this.client = null
        this.callback = null
        this.connected = false
        this.state = (typeof device.state !== 'undefined') ? device.state : Events.Unregist
        this.name = device.name

        this.siteId = device.siteId
        this.buildingId = device.buildingId
        this.floorId = device.floorId
        this.buildingName = device.buildingName
        this.floorName = device.floorName
        this.base = device.base
    
        this.logState = device.logState
        this.typeSensor = device.typeSensor
        this.typeHW = device.typeHW
        this.typeSW = device.typeSW
        this.verFW = device.verFW
        this.detectType = device.detectType
        this.detectTypeConv = device.detectTypeConv
        this.sensitivityFire = device.sensitivityFire
        this.sensitivityIntr = device.sensitivityIntr
        this.checkInterval = device.checkInterval
        this.checkRepeat = device.checkRepeat
        this.checkRepeatInterval = device.checkRepeatInterval
        this.checkRepeatCount = device.checkRepeatCount
        this.videoUrl = device.videoUrl
        this.sche = device.sche
        // this.sound = device.sound
        // this.thr = device.thr
        // this.als = device.als
        this.def = device.def
        this.thr = device.thr
        this.alsa = device.alsa
        this.sound = device.sound
        this.retry = 0;
    }

    get devState() { return this.state }

    get data() { return {
        sn: this.sn, 
        name: this.name, 
        state: this.state,
        siteId: this.siteId,
        buildingId: this.buildingId,
        floorId: this.floorId,
        buildingName: this.buildingName,
        floorName: this.floorName,
        base: this.base,
        logState: this.logState,
        typeSensor: this.typeSensor,
        typeHW: this.typeHW,
        typeSW: this.typeSW,
        verFW: this.verFW,
        detectType: this.detectType,
        detectTypeConv: this.detectTypeConv,
        sensitivityFire: this.sensitivityFire,
        sensitivityIntr: this.sensitivityIntr,
        sche: this.sche,
        // sound: this.sound
        // thr: this.thr
        // als: this.als
        def: this.def,
        thr: this.thr,
        alsa: this.alsa,
        sound: this.sound
    }}

    ///////////////////////////////////////////////////////////////////////////////
    connectMQTT(callback, iot) {
        if (callback) this.callback = callback

        if (this.client && this.connected) {
            if (this.state === Events.Unregist) {
                this.subscribeMQTT(`${mqttType}/device/${this.sn}/initialize`)
            } else {
                this.subscribeMQTT(`${mqttType}/${this.siteId}/${this.buildingId}/${this.floorId}/${this.sn}/configuration`)
            }
            return
        }

        if (config.region) {
            this.client = AWSIOTData.device({
                region: config.region, 
                host: config.host, 
                clientId: 'SOFIS_DEV_' + this.generateCode(6) + '_' + this.sn, 
                protocol: 'wss', 
                maximumReconnectTimeMs: 10000, 
                // debug: true, 
                accessKeyId: '', 
                secretKey: '',
                sessionToken: '',
                will: { topic: `${mqttType}/device/${this.sn}/disconnect`, payload: global.JSON.stringify({ sn: this.sn }), qos: 1 }
            })
            console.log('client: ', this.client);
    
            var cognitoIdentity = new AWS.CognitoIdentity()
            AWS.config.credentials.get((error, data) => {
                if (!error) {
                    var params = { IdentityId: AWS.config.credentials.identityId }
    
                    cognitoIdentity.getCredentialsForIdentity(params, (error, data) => {
                        if (!error) {
                            if (this.client) {
                                this.client.updateWebSocketCredentials(
                                    data.Credentials.AccessKeyId, 
                                    data.Credentials.SecretKey, 
                                    data.Credentials.SessionToken
                                )
                            }
                        } else {
                            console.log('error retrieving credentials: ', error)
                        }
                    })
                } else {
                    console.log('error retrieving identity: ', error)
                }
            })
        } else {
            // 폐쇄형서버
            // const parsed = url.parse(config.host);
            // const proxyOpts = url.parse(config.proxyHost);
            // proxyOpts.secureEndpoint = parsed.protocol ? parsed.protocol === 'wss': true;
            // const agent = new HttpsProxyAgent(proxyOpts);
            // const wsOptions = {
            //     agent: agent
            // }
            const options = {
                keepalive: 30, 
                reconnectPeriod: 1000,
                connectTimeout: 30 * 1000,
                // reschedulePings: true, 
                protocolId: 'MQTT', 
                protocolVersion: 4,
                // ca: [caData],
                // key: keyData,
                // cert: certData,
                clean: true, 
                // secureProtocol: 'TLSv1_2_method', 
                clientId: 'SOFIS_CLOSED_' + this.generateCode(6) + '_' + this.sn,
                rejectUnauthorized: false,
                will: { topic: `${mqttType}/device/${this.sn}/disconnect`, payload: global.JSON.stringify({ sn: this.sn }), qos: 1 }, 
                // wsOptions: wsOptions
            };
    
            this.client = mqtt.connect(config.host, options);
        }

        this.client.on('connect', (connack) => {
            console.log('mqtt connected: ', connack, ', state: ', this.state);

            if (connack.returnCode > 0) {
                console.log('connection error code: ', connack.returnCode);
                return;
            }
            this.connected = true
            if (this.state < Events.Active) {
                this.subscribeMQTT(`${mqttType}/device/${this.sn}/initialize`)

                // register
                if (iot) {
                    this.registerIOT();
                } else {
                    this.register();
                }    
            }
        });

        this.client.on('reconnect', () => {
            // console.log('mqtt reconnect: ', this.connected);
            if (this.connected) {
                if (this.state === Events.Unregist) {
                    this.subscribeMQTT(`${mqttType}/device/${this.sn}/initialize`)
                } else {
                    this.subscribeMQTT(`${mqttType}/${this.siteId}/${this.buildingId}/${this.floorId}/${this.sn}/configuration`)
                }
            }
        });

        this.client.on('error', (error) => {
            if (error) {
                console.log('mqtt error: ', typeof error === Object ? JSON.stringify(error):error);
                var params = { IdentityId: AWS.config.credentials.identityId }

                cognitoIdentity.getCredentialsForIdentity(params, (error, data) => {
                    if (!error) {
                        if (this.client) {
                            this.client.updateWebSocketCredentials(
                                data.Credentials.AccessKeyId, 
                                data.Credentials.SecretKey, 
                                data.Credentials.SessionToken
                            )    
                        }
                    } else {
                        console.log('error retrieving credentials: ', error)
                    }
                })
            }
            this.connected = false
            // process.exit(0);
        });

        this.client.on('offline', () => {
            this.connected = false
        })

        this.client.on('message', (topic, message, packet) => {
            console.log('topic: ' + topic);
            console.log('message: ' + message);
            console.log('packet: ', packet);
            const data = global.JSON.parse(message);

            if (this.callback) this.callback(topic, data);
            if (topic.endsWith('initialize')) {
                this.initialize(topic, data)
            } else if (topic.endsWith('configuration')) {
                this.configuration(topic, data)
            }
        });
    }

    generateCode(n) {
        let str = ''
        for (let i = 0; i < n; i++) {
          str += Math.floor(Math.random() * 10)
        }
        return str
    }

    ///////////////////////////////////////////////////////////////////////////////
    disconnectMQTT() {
        if (this.client) {
            console.log('disconnect:', this.sn)
            this.state = Events.Offline
            this.client.end(true)
        }
        if (this.callback) this.callback(mqttType + '/device/' + this.sn + '/disconnect', { sn: this.sn });
        this.client = null;
    }

    ///////////////////////////////////////////////////////////////////////////////
    publishMQTT(topic, message, opts) {
        if (this.client && this.connected) {
            if (this.callback) this.callback(topic, message);
            return this.client.publish(topic, message, opts)
        }
        console.log('mqtt not connected: in Publish')
        this.retry = 5
        setTimeout(this.retryPublish, 5000, this, topic, message, opts)
    }

    ///////////////////////////////////////////////////////////////////////////////
    retryPublish(dev, topic, message, opts) {
        if (dev.client && dev.client.connected) {
            return dev.client.publish(topic, message, opts)
        }
        console.log('mqtt not connected: in RetryPublish')
        if (this.retry <= 0) return
        this.retry -= 1;
        setTimeout(dev.retryPublish, 5000, dev, topic, message, opts)
    }

    ///////////////////////////////////////////////////////////////////////////////
    subscribeMQTT(topic) {
        if (this.client && this.connected) return this.client.subscribe(topic)
        console.log('mqtt not connected: in subscribe')
    }

    ///////////////////////////////////////////////////////////////////////////////
    unsubscribeMQTT(topic) {
        if (this.client && this.connected) return this.client.unsubscribe(topic)
        console.log('mqtt not connected: in unsubscribe')
    }

    ///////////////////////////////////////////////////////////////////////////////
    register() {
        console.log('publish register')

        const message = {
            sn: this.sn,
            logState: this.logState,
            typeSensor: this.typeSensor,
            typeHW: this.typeHW,
            typeSW: this.typeSW,
            verFW: this.verFW,
            deviceState: this.state, 
            detectType: this.detectType,
            detectTypeConv: this.detectTypeConv,
            sensitivityFire: this.sensitivityFire,
            sensitivityIntr: this.sensitivityIntr, 
            checkInterval: this.checkInterval, 
            checkRepeat: this.checkRepeat,
            checkRepeatInterval: this.checkRepeatInterval,
            checkRepeatCount: this.checkRepeatCount,
            videoUrl: this.videoUrl, 
            sche: this.sche,
            // sound: this.sound,
            // thr: this.thr,
            // als: this.als, 
            def: this.def, 
            thr: this.thr, 
            alsa: this.alsa,
            sound: this.sound, 
            evtTime: new Date().toISOString()
        }
        this.publishMQTT(`${mqttType}/device/${this.sn}/register`, global.JSON.stringify(message))
    }

    ///////////////////////////////////////////////////////////////////////////////
    registerIOT() {
        console.log('publish register')

        const message = {
            sn: this.sn,
            siteId: this.siteId,
            deviceName: this.name,
            logState: this.logState,
            typeSensor: this.typeSensor,
            typeHW: this.typeHW,
            typeSW: this.typeSW,
            verFW: this.verFW,
            deviceState: this.state, 
            detectType: this.detectType,
            detectTypeConv: this.detectTypeConv,
            sensitivityFire: this.sensitivityFire,
            sensitivityIntr: this.sensitivityIntr, 
            sche: this.sche,
            // sound: this.sound,
            // thr: this.thr,
            // als: this.als, 
            def: this.def, 
            thr: this.thr, 
            alsa: this.alsa,
            sound: this.sound, 
            evtTime: new Date().toISOString()
        }
        this.publishMQTT(`${mqttType}/device/${this.sn}/register`, global.JSON.stringify(message))
    }

    ///////////////////////////////////////////////////////////////////////////////
    initialize(topic, data) {
        if (data.regStatus < 0) {
            // unregister
            console.log('initialize state unregister')
            this.state = Events.Unregist;
            // 60초 뒤 재시도?
            // setTimeout(this.register(), 60000)
        } else {
            console.log('initialize state register state')
            // register
            this.state = Events.Active
            this.siteId = data.siteId;
            this.buildingId = data.buildingId;
            this.floorId = data.floorId;
            this.buildingName = data.buildingName;
            this.floorName = data.floorName;
            this.base = data.base;
            this.name = data.deviceName;
            // publish first state
            this.pubState({
                evtState: this.state,
                siteId: this.siteId,
                buildingId: this.buildingId,
                floorId: this.floorId,
                buildingName: this.buildingName, 
                floorName: this.floorName, 
                base: this.base,
                deviceName: this.name, 
                typeSensor: this.typeSensor, 
                sn: this.sn,
                logType: 'state', 
                evtTime: new Date().toISOString()
            })
            // change subscribe
            this.unsubscribeMQTT(`${mqttType}/device/${this.sn}/initialize`)
            this.subscribeMQTT(`${mqttType}/${this.siteId}/${this.buildingId}/${this.floorId}/${this.sn}/configuration`)
            
            // auto start
            this.start()
            this.active() // 서버에 현재상태를 전송
        }
    }

    encodeUtf8(data) {
        const dataBuff = Buffer.from(data)
        return dataBuff.toString('utf8')
    }

    encodeBase64(data) {
        const dataBuff = Buffer.from(data)
        return dataBuff.toString('base64')
    }

    ///////////////////////////////////////////////////////////////////////////////
    pubState(data) {
        if (this.state <= Events.Standby) return;
        // const message = encodeURIComponent(global.JSON.stringify(data))
        // console.log('publish State: ', message)
        // if (data.buildingName) data.buildingName = this.encodeBase64(data.buildingName)
        // if (data.floorName) data.floorName = this.encodeBase64(data.floorName)
        // if (data.deviceName) data.deviceName = this.encodeBase64(data.deviceName)
        if (data.buildingName) data.buildingName = this.encodeUtf8(data.buildingName)
        if (data.floorName) data.floorName = this.encodeUtf8(data.floorName)
        if (data.deviceName) data.deviceName = this.encodeUtf8(data.deviceName)
        this.publishMQTT(`${mqttType}/${this.siteId}/${this.buildingId}/${this.floorId}/${this.sn}/state`, global.JSON.stringify(data), { qos: 1 })
        // this.publishMQTT(`${mqttType}/${this.siteId}/${this.buildingId}/${this.floorId}/${this.sn}/state`, message, { qos: 1 })
    }

    ///////////////////////////////////////////////////////////////////////////////
    configuration(topic, data) {
        if (data.topic) {
            console.log('configuration: change topic')
            // change topic
            this.unsubscribeMQTT(`${mqttType}/${this.siteId}/${this.buildingId}/${this.floorId}/${this.sn}/configuration`)
            this.subscribeMQTT(data.topic);
            this.siteId = data.siteId;
            this.buildingId = data.buildingId;
            this.floorId = data.floorId;
        }
        if (data.command !== 'set') {
            console.log('configuration: cmd ', data.command)
            const result = {
                siteId: data.siteId,
                buildingId: data.buildingId, 
                floorId: data.floorId, 
                sn: this.sn, 
                logType: 'result', 
                commanderId: data.commanderId, 
                command: data.command, 
                configId: data.configId, 
                evtTime: new Date().toISOString()
            }
            // run command
            if (data.command === 'start') {
                this.start()
                this.resultConf(result);
            } else if (data.command === 'stop') {
                this.stop()
                this.resultConf(result);
            } else if (data.command === 'reboot') {
                this.resultConf(result);
                setTimeout((This) => {
                    This.reboot()
                }, 500, this)
                // this.reboot()
            } else if (data.command === 'delete') {
                this.resultConf(result)
                setTimeout((This) => { This.delete() }, 500, this)
            } else if (data.command === 'send') {
                console.log('send command empty')
            } else if (data.command === 'update') {
                console.log('receive firmware update')
                this.resultConf(result)
                this.reboot()
            } else if (data.command === 'initEnv') {
                console.log('initialize Sensing Environment')
                this.resultConf(result)
            }
        } else {
            console.log('configuration: set')
            let result = {
                siteId: data.siteId,
                buildingId: data.buildingId, 
                floorId: data.floorId, 
                sn: this.sn, 
                logType: 'result', 
                commanderId: data.commanderId, 
                command: data.command, 
                configId: data.configId, 
                evtTime: new Date().toISOString()
            }
            // update configuration value
            if (data.logState) {
                this.logState = data.logState;
                result.logState = data.logState;
            }
            if (data.buildingName) {
                this.buildingName = data.buildingName;
                result.buildingName = data.buildingName;
            }
            if (data.floorName) {
                this.floorName = data.floorName;
                result.floorName = data.floorName;
            }
            if (data.base) {
                this.base = data.base;
                result.base = data.base;
            }
            if (data.deviceName) {
                this.name = data.deviceName;
                result.deviceName = data.deviceName;
            }
            if (data.typeHW) {
                this.typeHW = data.typeHW;
                result.typeHW = data.typeHW;
            }
            if (data.typeSW) {
                this.typeSW = data.typeSW;
                result.typeSW = data.typeSW;
            }
            if (data.verFW) {
                this.verFW = data.verFW;
                result.verFW = data.verFW;
            }
            if (data.detectType) {
                this.detectType = data.detectType;
                result.detectType = data.detectType;
            }
            if (data.detectTypeConv) {
                this.detectTypeConv = data.detectTypeConv;
                result.detectTypeConv = data.detectTypeConv;
            }
            if (data.sensitivityFire) {
                this.sensitivityFire = data.sensitivityFire;
                result.sensitivityFire = data.sensitivityFire;
            }
            if (data.sensitivityIntr) {
                this.sensitivityIntr = data.sensitivityIntr;
                result.sensitivityIntr = data.sensitivityIntr;
            }
            // add checkInterval
            if (typeof data.checkInterval != 'undefined') {
                this.checkInterval = data.checkInterval
                result.checkInterval = data.checkInterval
            }
            // checkRepeat
            if (typeof data.checkRepeat != 'undefined') {
                this.checkRepeat = data.checkRepeat
                result.checkRepeat = data.checkRepeat
            }
            // checkRepeatInterval
            if (typeof data.checkRepeatInterval != 'undefined') {
                this.checkRepeatInterval = data.checkRepeatInterval
                result.checkRepeatInterval = data.checkRepeatInterval
            }
            // checkRepeatCount
            if (typeof data.checkRepeatCount != 'undefined') {
                this.checkRepeatCount = data.checkRepeatCount
                result.checkRepeatCount = data.checkRepeatCount
            }
            if (data.sche) {
                this.sche = data.sche;
                result.sche = data.sche;
            }
            if (data.def) {
                this.def = data.def;
                result.def = data.def;
            }
            if (data.thr) {
                this.thr = data.thr;
                result.thr = data.thr;
            }
            if (data.alsa) {
                this.alsa = data.alsa;
                result.alsa = data.alsa;
            }
            // 하위호환용(곧 사라질 녀석)
            if (data.als) {
                this.als = data.als;
                result.als = data.als;
            }
            if (data.sound) {
                this.sound = data.sound;
                result.sound = data.sound;
            }
            this.resultConf(result);
        }
        // publish result
    }

    ///////////////////////////////////////////////////////////////////////////////
    resultConf(data) {
        this.publishMQTT(`${mqttType}/${this.siteId}/${this.buildingId}/${this.floorId}/${this.sn}/result`, global.JSON.stringify(data), { qos: 1 })
    }

    ///////////////////////////////////////////////////////////////////////////////
    result(command) {
        if (this.state <= Events.Standby) return;

        const data = {
            siteId: this.siteId,
            buildingId: this.buildingId, 
            floorId: this.floorId, 
            sn: this.sn, 
            logType: 'result', 
            commanderId: 'scheduler', 
            command: command,
            configId: -1, 
            evtTime: new Date().toISOString()
        }
        this.publishMQTT(`${mqttType}/${this.siteId}/${this.buildingId}/${this.floorId}/${this.sn}/result`, global.JSON.stringify(data))
    }

    ///////////////////////////////////////////////////////////////////////////////
    boot(callback) {
        // connect mqtt
        this.connectMQTT(callback);
        
        // this.start();
    }

    ///////////////////////////////////////////////////////////////////////////////
    bootForIOT(sn, name, siteId, callback) {
        this.siteId = siteId
        this.name = name
        this.sn = sn

        // connect mqtt for IOT
        this.connectMQTT(callback, true);
        // this.start();
    }

    ///////////////////////////////////////////////////////////////////////////////
    start() {
        if (this.state > Events.Unregist) this.state = Events.Active;
    }

    ///////////////////////////////////////////////////////////////////////////////
    stop() {
        if (this.state > Events.Unregist) this.state = Events.Standby;
    }

    ///////////////////////////////////////////////////////////////////////////////
    reboot() {
        this.disconnectMQTT();

        this.boot();
    }

    ///////////////////////////////////////////////////////////////////////////////
    delete() {
        if (this.state === Events.Unregist) {
            this.unsubscribeMQTT(`${mqttType}/device/${this.sn}/initialize`)
        } else {
            this.unsubscribeMQTT(`${mqttType}/${this.siteId}/${this.buildingId}/${this.floorId}/${this.sn}/configuration`)
        }
        this.disconnectMQTT();
        if (this.state > Events.Unregist) this.state = Events.Offline 
    }

    ///////////////////////////////////////////////////////////////////////////////
    shutdown() {
        this.disconnectMQTT();
        if (this.state > Events.Unregist) this.state = Events.Offline 
    }

    ///////////////////////////////////////////////////////////////////////////////
    fire() {
        if (this.state <= Events.Standby) return;
        this.state = Events.Fire
        // publish first state
        this.pubState({
            evtState: Events.Fire,
            siteId: this.siteId,
            buildingId: this.buildingId,
            floorId: this.floorId,
            buildingName: this.buildingName, 
            floorName: this.floorName, 
            base: this.base, 
            deviceName: this.name, 
            typeSensor: this.typeSensor, 
            sn: this.sn,
            logType: 'state', 
            evtTime: new Date().toISOString()
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    cooling() {
        if (this.state <= Events.Standby) return;
        this.state = Events.Cooling
        // publish first state
        this.pubState({
            evtState: Events.Cooling,
            siteId: this.siteId,
            buildingId: this.buildingId,
            floorId: this.floorId,
            buildingName: this.buildingName, 
            floorName: this.floorName, 
            base: this.base, 
            deviceName: this.name, 
            typeSensor: this.typeSensor, 
            sn: this.sn,
            logType: 'state', 
            evtTime: new Date().toISOString()
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    invasion() {
        if (this.state <= Events.Standby) return;
        this.state = Events.Invasion
        // publish first state
        this.pubState({
            evtState: Events.Invasion,
            siteId: this.siteId,
            buildingId: this.buildingId,
            floorId: this.floorId,
            buildingName: this.buildingName, 
            floorName: this.floorName, 
            base: this.base, 
            deviceName: this.name, 
            typeSensor: this.typeSensor, 
            sn: this.sn,
            logType: 'state', 
            evtTime: new Date().toISOString()
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    warning() {
        if (this.state <= Events.Standby) return;
        this.state = Events.Warning
        // publish first state
        this.pubState({
            evtState: Events.Warning,
            siteId: this.siteId,
            buildingId: this.buildingId,
            floorId: this.floorId,
            buildingName: this.buildingName, 
            floorName: this.floorName, 
            base: this.base, 
            deviceName: this.name, 
            typeSensor: this.typeSensor, 
            sn: this.sn,
            logType: 'state', 
            evtTime: new Date().toISOString()
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    active() {
        if (this.state <= Events.Standby) return;
        this.state = Events.Active
        // publish first state
        this.pubState({
            evtState: Events.Active,
            siteId: this.siteId,
            buildingId: this.buildingId,
            floorId: this.floorId,
            buildingName: this.buildingName, 
            floorName: this.floorName, 
            base: this.base, 
            deviceName: this.name, 
            typeSensor: this.typeSensor, 
            sn: this.sn,
            logType: 'state', 
            evtTime: new Date().toISOString()
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    notMove() {
        if (this.state <= Events.Standby) return;
        this.state = Events.NotMove
        this.pubState({
            evtState: Events.NotMove,
            siteId: this.siteId,
            buildingId: this.buildingId,
            floorId: this.floorId,
            buildingName: this.buildingName, 
            floorName: this.floorName, 
            base: this.base, 
            deviceName: this.name, 
            typeSensor: this.typeSensor, 
            sn: this.sn,
            logType: 'state', 
            evtTime: new Date().toISOString()
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    event1() {
        if (this.state <= Events.Standby) return;
        this.state = Events.Event1
        this.pubState({
            evtState: Events.Event1,
            siteId: this.siteId,
            buildingId: this.buildingId,
            floorId: this.floorId,
            buildingName: this.buildingName, 
            floorName: this.floorName, 
            base: this.base, 
            deviceName: this.name, 
            typeSensor: this.typeSensor, 
            sn: this.sn,
            logType: 'state', 
            evtTime: new Date().toISOString()
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    event2() {
        if (this.state <= Events.Standby) return;
        this.state = Events.event2
        this.pubState({
            evtState: Events.Event2,
            siteId: this.siteId,
            buildingId: this.buildingId,
            floorId: this.floorId,
            buildingName: this.buildingName, 
            floorName: this.floorName, 
            base: this.base, 
            deviceName: this.name, 
            typeSensor: this.typeSensor, 
            sn: this.sn,
            logType: 'state', 
            evtTime: new Date().toISOString()
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    sError() {
        const errMessages = [
            '001- system error', 
            '002- sensor error', 
            '003- file io error', 
            '004- initialize error', 
            '005- configuration error', 
        ]

        if (this.state < Events.Standby) return;

        const idx = Math.floor(Math.random() * errMessages.length * 10) % errMessages.length

        this.state = Events.Error
        this.pubState({
            evtState: Events.Error,
            siteId: this.siteId,
            buildingId: this.buildingId,
            floorId: this.floorId,
            buildingName: this.buildingName, 
            floorName: this.floorName, 
            base: this.base, 
            deviceName: this.name, 
            typeSensor: this.typeSensor, 
            sn: this.sn,
            logType: 'state', 
            errMsg: errMessages[idx],
            evtTime: new Date().toISOString()
        })
    }
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
export class DevManager {
    static stressTest = false;
    static stressCount = 0;

    ///////////////////////////////////////////////////////////////////////////////
    constructor(devices = []) {
        this.devices = devices
        this.workDevices = devices
        this.data = null
        this.running = false
    }

    ///////////////////////////////////////////////////////////////////////////////
    setDeviceData(data) {
        this.data = data;
    }

    ///////////////////////////////////////////////////////////////////////////////
    makeDevice(sn, name) {
        const data = this.data ? this.data : {};
        const device = {
            sn: sn, 
            name: name,
            state: (typeof data.state !== 'undefined') ? data.state : Events.Unregist,
            
            siteId: data.siteId ? data.siteId : null,
            buildingId: data.buildingId ? data.buildingId : null,
            floorId: data.floorId ? data.floorId : null, 
            buildingName: data.buildingName,
            floorName: data.floorName,

            logState: data.logState ? data.logState : null,
            typeSensor: data.typeSensor ? data.typeSensor : null,
            typeHW: data.typeHW ? data.typeHW : null,
            typeSW: data.typeSW ? data.typeSW : null,
            verFW: data.verFW ? data.verFW : null,
            detectType: data.detectType ? data.detectType : null,
            detectTypeConv: data.detectTypeConv ? data.detectTypeConv : null,
            sensitivityFire: data.sensitivityFire ? data.sensitivityFire : null,
            sensitivityIntr: data.sensitivityIntr ? data.sensitivityIntr : null,
            sche: data.sche ? data.sche : [],
            // sound: data.sound ? data.sound : [],
            // thr: data.thr ? data.thr : null,
            // als: data.als ? data.als : null
            def: data.def ? data.def : null, 
            thr: data.thr ? data.thr : null,
            alsa: data.alsa ? data.alsa : null, 
            sound: data.sound ? data.sound: null
        }
        if (data.floor) {
            device.buildingId = data.floor.buiding_id
            device.floorId = data.floorId
            device.buildingName = data.floor.building.buildingName
            device.floorName = data.floor.floorName
        }
        return device;
    }
    
    ///////////////////////////////////////////////////////////////////////////////
    add(device) {
        const index = this.devices.findIndex((dev) => dev.sn === device.sn)
        console.log('duplicate: ', index)
        if (index >= 0) return
        this.devices.push(device)
    }

    ///////////////////////////////////////////////////////////////////////////////
    insert(index, device) {
        const idx = this.devices.findIndex((dev) => dev.sn === device.sn)
        console.log('duplicate: ', idx)
        if (idx >= 0) return
        this.devices.splice(index, 0, device)
    }

    ///////////////////////////////////////////////////////////////////////////////
    get list() { return this.devices }

    ///////////////////////////////////////////////////////////////////////////////
    get started() { return this.running }

    ///////////////////////////////////////////////////////////////////////////////
    mod(device) {
        const index = this.devices.findIndex((dev) => dev.sn === device.sn)
        if (index >= 0) return
        this.devices[index] = device;
    }

    ///////////////////////////////////////////////////////////////////////////////
    del(sn) {
        const index = this.devices.findIndex((dev) => dev.sn === sn)
        if (index >= 0) return
        this.devices.splice(index, 1);
    }

    ///////////////////////////////////////////////////////////////////////////////
    loadDevices() {
        const devicesString = localStorage.getItem('testDevices')
        if (devicesString) {
            const devices = global.JSON.parse(devicesString)
            devices.forEach(device => {
                this.add(new Device(device));
            })
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    saveDevices() {
        const devicesString = global.JSON.stringify(this.devices)
        localStorage.setItem('testDevices', devicesString)
    }

    ///////////////////////////////////////////////////////////////////////////////
    removeAll() {
        let count = this.devices.length
        this.devices.forEach(dev => {
            console.log('delete: ', dev)
            dev.delete()
            count -= 1
            if (count <= 0) {
                this.devices.splice(0, this.devices.length)
                this.devices = []
            }
        })
    }


    ///////////////////////////////////////////////////////////////////////////////
    setWorkDevices(selects) {
        this.workDevices = []
        if (!selects) {
            for(var i = 0; i < this.devices.length; i++) {
                this.workDevices.push(this.devices[i]);
            }
            return;
        }
        for(i = 0; i < selects.length; i++)
        {
            const device = selects[i]
            const index = this.devices.findIndex(item => item.sn === device.sn)
            console.log('findIndex: ', index)
            if (index >= 0) this.workDevices.push(this.devices[index])
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    randomWorkDevices(num, active) {
        var devices = this.devices;
        var evts = [];

        console.log('num:' + num + ', active:' + active)
        if (active) {
            devices = this.devices.filter(device => device.state > Events.Standby)
        }
        console.log('source devices count(active device):', devices.length)


        if (num >= devices.length) {
            evts = devices;
        } else {
            while (evts.length < num) {
                const index = Math.floor(Math.random() * devices.length * 10) % devices.length
                if (index < devices.length) {
                    const sn = devices[index].sn;
                    const idx = evts.findIndex(dev => dev.sn === sn)
                    if (idx < 0) evts.push(devices[index])
                }
            }
        }
        console.log('result devices count:', evts.length)
        this.workDevices = evts
    }

    ///////////////////////////////////////////////////////////////////////////////
    boot(callback) {
        var time = 0;
        // 10개씩 끊어서 1초간격으로 부팅
        for (var i = 0; i < this.workDevices.length; i++) {
            setTimeout((device, callback) => {
                device.boot(callback)
            }, time * 1000, this.workDevices[i], callback)
            if ((i + 1) % 10 === 0) time += 1;
        }
        this.running = true;
    }

    ///////////////////////////////////////////////////////////////////////////////
    shutdown() {
        this.workDevices.forEach((dev) => {
            dev.shutdown()
        })
        this.running = false;
    }

    ///////////////////////////////////////////////////////////////////////////////
    fire(count, callback) {
        console.log('fire events count: ' + count)
        console.log('test device count: ', this.workDevices.length);
        DevManager.fireLoop(this.workDevices, count, callback)
    }

    static fireLoop(evts, count, callback) {
        console.log('event devices size: ' + evts.length)
        evts.forEach((dev) => {
            if (dev.state > Events.Standby) {
                dev.fire()
                if (callback) callback(fireEvt, dev)    
            }
        })
        count -= 1
        if (count > 0) {
            setTimeout(DevManager.fireLoop, 1500, evts, count, callback)
        } else {
            setTimeout(DevManager.endOfEvent, 1500, evts, 3, callback)
            // setTimeout((evts) => {
            //     evts.forEach((dev) => {
            //         if (dev.state > Events.Standby) {
            //             dev.active()
            //             if (callback) callback(activeEvt, dev)
            //         }
            //     })
            // }, 1500, evts)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    cooling(count, callback) {
        console.log('cooling events count: ' + count)
        console.log('test device count: ', this.workDevices.length);
        DevManager.coolingLoop(this.workDevices, count, callback)
    }

    static coolingLoop(evts, count, callback) {
        console.log('event devices size: ' + evts.length)
        evts.forEach((dev) => {
            if (dev.state > Events.Standby) {
                dev.cooling()
                if (callback) callback(coolingEvt, dev)    
            }
        })
        count -= 1
        if (count > 0) {
            setTimeout(DevManager.coolingLoop, 1500, evts, count, callback)
        } else {
            setTimeout(DevManager.endOfEvent, 1500, evts, 3, callback)
            // setTimeout((evts) => {
            //     evts.forEach((dev) => {
            //         if (dev.state > Events.Standby) {
            //             dev.active()
            //             if (callback) callback(activeEvt, dev)
            //         }
            //     })
            // }, 1500, evts)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    invasion(count, callback) {
        console.log('invasion events count: ' + count)
        DevManager.invasionLoop(this.workDevices, count, callback)
    }

    static invasionLoop(evts, count, callback) {
        evts.forEach((dev) => {
            if (dev.state > Events.Standby) {
                dev.invasion()
                if (callback) callback(invasionEvt, dev)    
            }
        })
        count -= 1
        if (count > 0) {
            setTimeout(DevManager.invasionLoop, 1500, evts, count, callback)
        } else {
            setTimeout(DevManager.endOfEvent, 1500, evts, 3, callback)
            // setTimeout((evts) => {
            //     evts.forEach((dev) => {
            //         if (dev.state > Events.Standby) {
            //             dev.active()
            //             if (callback) callback(activeEvt, dev)
            //         }
            //     })
            // }, 1500, evts)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    warning(count, callback) {
        console.log('warning events count: ' + count)
        DevManager.warningLoop(this.workDevices, count, callback)
    }

    static warningLoop(evts, count, callback) {
        evts.forEach((dev) => {
            if (dev.state > Events.Standby) {
                dev.warning()
                if (callback) callback(warningEvt, dev)    
            }
        })
        count -= 1
        if (count > 0) {
            setTimeout(DevManager.warningLoop, 1500, evts, count, callback)
        } else {
            setTimeout(DevManager.endOfEvent, 1500, evts, 3, callback)
            // setTimeout((evts) => {
            //     evts.forEach((dev) => {
            //         if (dev.state > Events.Standby) {
            //             dev.active()
            //             if (callback) callback(activeEvt, dev)
            //         }
            //     })
            // }, 1500, evts)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    notMove(count, callback) {
        console.log('not move events count: ' + count)
        DevManager.notMoveLoop(this.workDevices, count, callback)
    }

    static notMoveLoop(evts, count, callback) {
        evts.forEach((dev) => {
            if (dev.state > Events.Standby) {
                dev.notMove()
                if (callback) callback(notMoveEvt, dev)    
            }
        })
        count -= 1
        if (count > 0) {
            setTimeout(DevManager.notMoveLoop, 1500, evts, count, callback)
        } else {
            setTimeout(DevManager.endOfEvent, 1500, evts, 3, callback)
            // setTimeout((evts) => {
            //     evts.forEach((dev) => {
            //         if (dev.state > Events.Standby) {
            //             dev.active()
            //             if (callback) callback(activeEvt, dev)
            //         }
            //     })
            // }, 1500, evts)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    sError(count, callback) {
        console.log('error events count: ' + count)
        DevManager.sErrorLoop(this.workDevices, count, callback)
    }

    static sErrorLoop(evts, count, callback) {
        evts.forEach((dev) => {
            if (dev.state > Events.Standby) {
                dev.sError()
                if (callback) callback(errorEvt, dev)    
            }
        })
        count -= 1
        if (count > 0) {
            setTimeout(DevManager.sErrorLoop, 1500, evts, count, callback)
        } else {
            setTimeout(DevManager.endOfEvent, 1500, evts, 3, callback)
            // setTimeout((evts) => {
            //     evts.forEach((dev) => {
            //         if (dev.state > Events.Standby) {
            //             dev.active()
            //             if (callback) callback(activeEvt, dev)
            //         }
            //     })
            // }, 1500, evts)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////
    static endOfEvent(evts, count, callback) {
        evts.forEach((dev) => {
            if (dev.state > Events.Standby) {
                dev.active()
                if (callback) callback(activeEvt, dev)    
            }
        })
        count -= 1
        if (count <= 0) return
        setTimeout(DevManager.endOfEvent, 1500, evts, count, callback)
    }


    ///////////////////////////////////////////////////////////////////////////////
    startOfEvents(count, callback) {
        DevManager.stressTest = true;
        DevManager.stressCount = count;
        DevManager.eventLoop(this.workDevices, count, callback)
    }

    static eventLoop(evts, count, callback) {
        if (!DevManager.stressTest) {
            DevManager.endLoop(evts, callback)
            return
        }
        evts.forEach((dev) => {
            if (dev.state > Events.Standby) {
                const event = Math.floor(Math.random() * 2) % 2
                if (event === 0) {
                    dev.fire()
                    if (callback) callback(fireEvt, dev)    
                } else {
                    dev.invasion()
                    if (callback) callback(invasionEvt, dev)    
                }
            }
        })
        count -= 1
        if (count > 0) {
            setTimeout(DevManager.eventLoop, 1500, evts, count, callback)
        } else {
            DevManager.standbyLoop(evts, DevManager.stressCount, callback)
        }
    }

    static standbyLoop(evts, count, callback) {
        if (!DevManager.stressTest) {
            DevManager.endLoop(evts, callback)
            return
        }

        evts.forEach((dev) => {
            if (dev.state > Events.Standby) {
                dev.active()
                if (callback) callback(activeEvt, dev)
            }
        })
        count -= 1
        if (count > 0) {
            setTimeout(DevManager.standbyLoop, 1500, evts, count, callback)
        } else {
            DevManager.eventLoop(evts, DevManager.stressCount, callback)
        }
    }

    static endLoop(evts, callback) {
        DevManager.stressCount = 0;
        evts.forEach((dev) => {
            if (dev.state > Events.Standby) {
                dev.active()
                if (callback) callback(activeEvt, dev)
            }
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    endOfEvents() {
        DevManager.stressTest = false;
        DevManager.stressCount = 0;
    }

    ///////////////////////////////////////////////////////////////////////////////
    getDeviceStates() {
        var states = {
            unregister: 0, 
            offline: 0, 
            standby: 0, 
            active: 0, 
            fire: 0, 
            invasion: 0
        }
        for (var i = 0; i < this.devices.length; i++) {
            switch (this.devices[i].devState) {
                case Events.Unregist: {
                    states.unregister += 1;
                    break;
                }
                case Events.Offline: {
                    states.offline += 1;
                    break;
                }
                case Events.Standby: {
                    states.standby += 1;
                    break;
                }
                case Events.Active: {
                    states.active += 1;
                    break;
                }
                case Events.Fire: {
                    states.fire += 1;
                    break;
                }
                case Events.Invasion: {
                    states.invasion += 1;
                    break;
                }
            }
        }
        console.log('states: ', states);
        return states;
    }

    ///////////////////////////////////////////////////////////////////////////////
    schStart(callback) {
        // first event
        this.workDevices.forEach((dev) => {
            dev.start()
            dev.result('start');
            if (callback) callback(schStartEvt, dev)
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    schStop(callback) {
        // first event
        this.workDevices.forEach((dev) => {
            dev.stop()
            dev.result('stop');
            if (callback) callback(schStopEvt, dev)
        })
    }

    ///////////////////////////////////////////////////////////////////////////////
    schReboot(callback) {
        // first event
        this.workDevices.forEach((dev) => {
            dev.reboot()
            dev.result('reboot');
            if (callback) callback(schRebootEvt, dev)
        })
    }
}
