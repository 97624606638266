const state = {
	siteId: parseInt(localStorage.getItem('siteId'), 0), 
	serverType: localStorage.getItem('serverType')
}

const mutations = {
	SET_SITE_ID(state, id) {
		console.log('SET SITE ID: ', id)
		state.siteId = id
		localStorage.setItem('siteId', id.toString())
	}, 
	SET_SERVER_TYPE(state, type) {
		console.log('SET SITE ID: ', type)
		state.serverType = type
		localStorage.setItem('serverType', type)
	}
}

const actions = {
	setSiteId({ commit }, id) {
		// do something async
		commit('SET_SITE_ID', id)
	}, 
	setServerType({ commit }, type) {
		// do something async
		commit('SET_SERVER_TYPE', type)
	}
}

export default {
	state,
	mutations,
	actions
}
