<template>
	<div class="p-2">
		<div class="mt-2">
			<h4>MQTT</h4>
		</div>
		<div class="mb-3">
			<b-form-select size="sm" :options="servers" v-model="devType" @change="changeServerType">
			</b-form-select>
		</div>
		<div class="mt-2">
			<h4>테스트 메뉴</h4>
		</div>
		<div>
			<!-- <b-nav vertical pills small>
				<b-nav-item v-for="(item, idx) in menu" :key="idx" :href="item.link">
					{{item.text}}
				</b-nav-item>
			</b-nav> -->
			<b-list-group size="small">
				<b-list-group-item 
					class="font-size-11" 
					v-for="(item, idx) in menu" 
					:key="idx" 
					:href="item.link"
					:variant="item.variant"
				>
					{{item.text}}
				</b-list-group-item>
			</b-list-group>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
	data() {
		return {
			menu: [
				{ text: '기기 관리', link: '/#/initialize', variant: 'info' }, 
				{ text: '기능 테스트', link: '/', variant: 'primary' }, 
				{ text: '볼륨 테스트', link: '/#/volume', variant: 'danger' }, 
				{ text: '시나리오 테스트', link: '/#/scenario', variant: 'warning' }, 
				{ text: '로그아웃', link: '/#/logout', variant: 'dark' }
			], 
			servers: [
				{ text: '개발서버', value: 'dev'}, 
				{ text: '서비스서버', value: 'pro'}, 
				{ text: '폐쇄형서버', value: 'cld'}, 
				{ text: '시큐웍스폐쇄', value: 'sws'}
			], 
			devType: 'dev'
		}
	}, 
    computed: {
        ...mapState({
			serverType: state => state.Server.serverType
        })
	}, 
	mounted(){
		if (!this.serverType) {
			this.setServerType(this.devType);
		} else {
			this.devType = this.serverType;
		}
	},
	methods: {
		...mapActions(['setServerType']), 
		changeServerType(value) {
			console.log('select: ', value);
			this.setServerType(value);
			window.location.reload();
		}
	}
};
</script>
